$(document).ready(function(){
// search autocomplete
    var client = algoliasearch(process.env.MIX_ALGOLIA_APP_ID, process.env.MIX_ALGOLIA_SEARCH);
    var index = client.initIndex(process.env.MIX_ALGOLIA_INDEX_NAME_TESTPOSHOP);
    $('#search-input-autocomplete, #error-search-input-autocomplete').autocomplete(
        { hint: false }, [{
            source: $.fn.autocomplete.sources.hits(index, { hitsPerPage: 5 }),
            displayKey: 'articleName',
            templates: {
                suggestion: function(suggestion) {
                    return '<div class="autocomplete-search-result">' +
                        '<a href="/product/' + suggestion.slug + '">' +
                            '<span class="autocomplete-search-result-name">' + suggestion._highlightResult.articleName.value + '</span>' +
                            '<span class="autocomplete-search-result-id">' + suggestion._highlightResult.ownArticleNumber.value + '</span>' +
                            '<span class="autocomplete-search-result-price">&pound;' + suggestion._highlightResult.fromPrice.value + '</span>' +
                            '<span class="autocomplete-search-result-vatprice">&pound;' + suggestion._highlightResult.vatFromPrice.value + '</span>' +
                        '</a>' +
                    '</div>';
                },
                empty: function(options) {
                    return '<div class="autocomplete-no-results">No products found</div>';
                }
            }
        }
    ]).on('autocomplete:selected', function(event, suggestion, dataset) {
        console.log(suggestion, dataset);
    });
});
